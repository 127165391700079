import { default as authI5JGIXywJyMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as whytestkTIyJTSyujMeta } from "/vercel/path0/pages/blog/whytest.vue?macro=true";
import { default as failalSKjrDMT2Meta } from "/vercel/path0/pages/fail.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacypolicylsLIVqZO1GMeta } from "/vercel/path0/pages/info/privacypolicy.vue?macro=true";
import { default as termsofserviceNEFckPlZKLMeta } from "/vercel/path0/pages/info/termsofservice.vue?macro=true";
import { default as testheaderybtncivX0lMeta } from "/vercel/path0/pages/main/testheader.vue?macro=true";
import { default as pay38MeHRzpZfdMeta } from "/vercel/path0/pages/pay3.vue?macro=true";
import { default as successW4sLGie1V5Meta } from "/vercel/path0/pages/success.vue?macro=true";
import { default as testgets3erbgkBucUGMeta } from "/vercel/path0/pages/testgets3.vue?macro=true";
import { default as _91testId_93neCwPDJfhFMeta } from "/vercel/path0/pages/tests/[testId].vue?macro=true";
import { default as addcredits3yMBv4nlQ2Meta } from "/vercel/path0/pages/tests/addcredits.vue?macro=true";
import { default as AddTestqjxjyD6tzpMeta } from "/vercel/path0/pages/tests/AddTest.vue?macro=true";
import { default as AddTest2wlulihB892Meta } from "/vercel/path0/pages/tests/AddTest2.vue?macro=true";
import { default as AddTestSectionsiNxCNqXwMLMeta } from "/vercel/path0/pages/tests/AddTestSections.vue?macro=true";
import { default as flowLTtVxwOvV9Meta } from "/vercel/path0/pages/tests/flow.vue?macro=true";
import { default as TestDashboardQ6XAwcPGDtMeta } from "/vercel/path0/pages/tests/TestDashboard.vue?macro=true";
import { default as testing123yh9MdxlMQdMeta } from "/vercel/path0/pages/tests/testing123.vue?macro=true";
import { default as voteCAQMQVpiszMeta } from "/vercel/path0/pages/tests/vote.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/vercel/path0/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-whytest",
    path: "/blog/whytest",
    component: () => import("/vercel/path0/pages/blog/whytest.vue").then(m => m.default || m)
  },
  {
    name: "fail",
    path: "/fail",
    component: () => import("/vercel/path0/pages/fail.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-privacypolicy",
    path: "/info/privacypolicy",
    component: () => import("/vercel/path0/pages/info/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: "info-termsofservice",
    path: "/info/termsofservice",
    component: () => import("/vercel/path0/pages/info/termsofservice.vue").then(m => m.default || m)
  },
  {
    name: "main-testheader",
    path: "/main/testheader",
    component: () => import("/vercel/path0/pages/main/testheader.vue").then(m => m.default || m)
  },
  {
    name: "pay3",
    path: "/pay3",
    component: () => import("/vercel/path0/pages/pay3.vue").then(m => m.default || m)
  },
  {
    name: "success",
    path: "/success",
    component: () => import("/vercel/path0/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "testgets3",
    path: "/testgets3",
    component: () => import("/vercel/path0/pages/testgets3.vue").then(m => m.default || m)
  },
  {
    name: "tests-testId",
    path: "/tests/:testId()",
    component: () => import("/vercel/path0/pages/tests/[testId].vue").then(m => m.default || m)
  },
  {
    name: "tests-addcredits",
    path: "/tests/addcredits",
    meta: addcredits3yMBv4nlQ2Meta || {},
    component: () => import("/vercel/path0/pages/tests/addcredits.vue").then(m => m.default || m)
  },
  {
    name: "tests-AddTest",
    path: "/tests/AddTest",
    meta: AddTestqjxjyD6tzpMeta || {},
    component: () => import("/vercel/path0/pages/tests/AddTest.vue").then(m => m.default || m)
  },
  {
    name: "tests-AddTest2",
    path: "/tests/AddTest2",
    meta: AddTest2wlulihB892Meta || {},
    component: () => import("/vercel/path0/pages/tests/AddTest2.vue").then(m => m.default || m)
  },
  {
    name: "tests-AddTestSections",
    path: "/tests/AddTestSections",
    meta: AddTestSectionsiNxCNqXwMLMeta || {},
    component: () => import("/vercel/path0/pages/tests/AddTestSections.vue").then(m => m.default || m)
  },
  {
    name: "tests-flow",
    path: "/tests/flow",
    component: () => import("/vercel/path0/pages/tests/flow.vue").then(m => m.default || m)
  },
  {
    name: "tests-TestDashboard",
    path: "/tests/TestDashboard",
    meta: TestDashboardQ6XAwcPGDtMeta || {},
    component: () => import("/vercel/path0/pages/tests/TestDashboard.vue").then(m => m.default || m)
  },
  {
    name: "tests-testing123",
    path: "/tests/testing123",
    component: () => import("/vercel/path0/pages/tests/testing123.vue").then(m => m.default || m)
  },
  {
    name: "tests-vote",
    path: "/tests/vote",
    meta: voteCAQMQVpiszMeta || {},
    component: () => import("/vercel/path0/pages/tests/vote.vue").then(m => m.default || m)
  }
]